import React from "react";
import Link from "gatsby-link";

class PostListing extends React.Component {
  getPostList() {
    const postList = [];
    this.props.postEdges.forEach((postEdge) => {
      postList.push({
        path: postEdge.node.fields.slug.replace(/^.*\//, ''),
        tags: postEdge.node.frontmatter.tags,
        cover: postEdge.node.frontmatter.cover,
        title: postEdge.node.frontmatter.title,
        date: postEdge.node.frontmatter.date,
        excerpt: postEdge.node.excerpt,
        timeToRead: postEdge.node.timeToRead,
      });
    });
    return postList;
  }
  render() {
    const postList = this.getPostList();
    return (
      <div>
        {/* Your post list here. */
        postList.map((post, index) => (
          <div
            key={post.title}
            className="card"
            style={{
              marginTop: index === 0 ? 0 : 24,
              backgroundColor: "white" /*this.props.backgroundColor*/,
            }}
          >
            <div className="card-content">
              <div className="media">
                <div className="media-content">
                  <p
                    className={"title is-4 " + post.title}
                    style={{ color: "black" /*this.props.textColor*/ }}
                  >
                    {post.title}
                  </p>
                </div>
              </div>
              <div className={"content " + this.props.fontColor}>
                {post.excerpt}
                <br />
                <Link to={post.path}>Read more</Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default PostListing;
